import {
  DeliveryAddress,
  Measurements,
  Parcel,
  ParcelType,
  Product,
  Recipient,
} from "@/types/ParcelsTypes";
import { CostModel, ParcelCreateModelDeliveryModeEnum } from "@/api/client";

const newParcel = {
  state: {
    step: 1 as number,
    parcel: {
      sender: 2,
      hasBattery: false,
      discount: false,
      deliveryAddress: {} as DeliveryAddress,
      measurements: {} as Measurements,
      packageName: "",
      recipient: {} as Recipient,
      costInfo: {} as CostModel,
    } as Parcel,
  },
  getters: {
    getStep(state: { step: number }): number {
      return state.step;
    },
    getDeliveryType(state: { parcel: Parcel }): string | undefined {
      return state.parcel.deliveryType;
    },
    getSummaryInfo(state: { parcel: Parcel }) {
      const {
        estimatedValue,
        discount,
        deliveryAddress,
        measurements,
        products,
        recipient,
        costInfo,
      } = state.parcel;
      return {
        estimatedValue,
        discount,
        deliveryAddress,
        measurements,
        products,
        recipient,
        costInfo,
      };
    },
    getCalculateValue(state: { parcel: Parcel }) {
      const {
        estimatedValue,
        hasBattery,
        discount,
        deliveryAddress,
        parcelType,
        deliveryType,
        measurements,
      } = state.parcel;

      return {
        calculatorVersion: 2,
        ccd: hasBattery,
        cost_of_investment: estimatedValue,
        discountType: discount ? 1 : 0,
        cityCode: deliveryAddress.city.data.code,
        parcelType: parcelType,
        deliveryMode: deliveryType,
        packages: [measurements],
        route: "RUSPOST",
      };
    },
    getProducts(state: { parcel: Parcel }) {
      return state.parcel.products;
    },
    getParcel(state: { parcel: Parcel }) {
      return state.parcel;
    },
  },
  mutations: {
    setStep(state: { step: number }, step: number): void {
      state.step = step;
    },
    setSenderId(state: { parcel: Parcel }, senderId: number): void {
      state.parcel.sender = senderId;
    },
    setDeliveryType(
      state: { parcel: Parcel },
      value: ParcelCreateModelDeliveryModeEnum | undefined
    ): void {
      state.parcel.deliveryType = value;
    },
    setDeliveryAddress(
      state: { parcel: Parcel },
      address: DeliveryAddress
    ): void {
      state.parcel.deliveryAddress = address;
    },
    setParcelType(state: { parcel: Parcel }, data: ParcelType): void {
      state.parcel.deliveryType = data.delivery;
      state.parcel.parcelType = data.parcelType ? data.parcelType : "";
      state.parcel.estimatedValue = data.price;
      state.parcel.hasBattery = data.hasBattery;
      state.parcel.discount = data.discount;
    },
    setMeasurements(state: { parcel: Parcel }, data: Measurements) {
      state.parcel.measurements = data;
    },
    setPackageName(state: { parcel: Parcel }, data: string) {
      state.parcel.packageName = data;
    },
    setProducts(state: { parcel: Parcel }, data: Product[]) {
      state.parcel.products = data;
    },
    setRecipient(state: { parcel: Parcel }, data: Recipient) {
      state.parcel.recipient = data;
    },
    setCostInfo(state: { parcel: Parcel }, data: CostModel) {
      state.parcel.costInfo = data;
    },
    deleteProduct(state: { parcel: Parcel }, index: number): void {
      if (index >= 0 && index < state.parcel.products.length) {
        state.parcel.products.splice(index, 1);
      }
    },
  },
  actions: {},
};

export default newParcel;
